<template>
  <!-- <div class="div"> -->
  <div>
    <b-row>
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="require('@/assets/images/logo/logo.png')"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- Login-->
      <b-col
        lg="6"
        xl="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          xl=""
          class="px-xl-2 mx-auto"
        >
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->

              <div
                role="alert"
                :class="{ show: errors.length > 0 }"
                class="alert fade alert-danger"
              >
                <div class="alert-text">
                  {{ errors }}
                </div>
              </div>
              <b-form-group
                label="اسم المستخدم"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="login-email"
                      v-model="userName"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder=""
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">كلمة المرور</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              > تسجيل الدخول </b-button>
              <br>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="rememberMe"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-forgot-password' }">
              <feather-icon icon="ChevronLeftIcon" />
              <label>نسيان كلمة المرور</label>
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>

    <b-col class="div">
      <app-footer />
    </b-col>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BCard,
} from 'bootstrap-vue'
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { LOGIN } from '@/store/auth.module.js'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {

  components: {
    BCardCode,
    AppFooter,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      rememberMe: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },

    ...mapState({
      errors: state => state.auth.errors,
    }),
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  // created(){

  //    this.$swal({
  //         title: 'Server Ip',
  //         input: 'text',
  //         customClass: {
  //           confirmButton: 'btn btn-primary',
  //           cancelButton: 'btn btn-outline-danger ml-1',
  //         },
  //           allowEscapeKey: false,

  //       allowOutsideClick: false,
  //         buttonsStyling: false,
  //         inputAttributes: {
  //           autocapitalize: 'off',
  //         },

  //         confirmButtonText: 'ok',
  //         showLoaderOnConfirm: true,
  //       preConfirm(serverIp) {
  //           if (!serverIp) return null

  //       // //console.log(axiosIns.baseURL)
  //      Vue.prototype.$http.defaults.baseURL=serverIp
  //         localStorage.setItem('serverIp', serverIp)

  //         }})
  // },

  mounted() {
    this.userName = localStorage.getItem('logUserName')
    this.password = localStorage.getItem('logpassword')
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
       
        if (success) {
          const data = {
            username: this.userName,
            password: this.password,
          }
          // console.log(data);
          this.$store
            .dispatch(LOGIN, data)
            .then(res => {
              localStorage.removeItem('queryParams')

              // this.$router.replace(getHomeRouteForLoggedInUser(res.data.data.email))
              //   this.$router.push("verfication-code");

              // console.log("ress",res)
              localStorage.setItem('userData', JSON.stringify(res.data.user))
              localStorage.setItem('token', res.data.token)
              const UserData = JSON.parse(localStorage.getItem('permissions'))
              const userData = JSON.parse(localStorage.getItem('userData'))
              const permissions = JSON.parse(localStorage.getItem('permissions'))
             
              this.$ability.update(permissions)
            
              this.$router.push('/dashboard')
            
              if(userData.has_changed == 0) this.$router.push('/apps/users/change-password')
           
            })
            .catch(err => console.log(err))

          //     //console.log(useJwt.login)
          //      useJwt.login({
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     //console.log("vuex",response)
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data.userData))
          //     this.$ability.update(response.data.userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            //     .then(() => {
            //       this.$toast({
            //         component: ToastificationContent,
            //         position: 'top-right',
            //         props: {
            //           title: `Welcome ${userData.fullName || userData.username}`,
            //           icon: 'CoffeeIcon',
            //           variant: 'success',
            //           text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //         },
            //       })
            //     })
            // })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    lsRememberMe() {

    },
  },
}
</script>

<style scoped>
.div {
  margin-top: 125px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
