<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
<!-- Baraem ERP System, Suwayda Branch | V 1.0.5 Beta -->
<!-- Baraem ERP System, Swayda Branch | V 1.2.6 Stable -->
Baraem ERP System, Swayda Branch | V 1.2.9 Stable

<!-- Baraem ERP System, Quneitra Branch | V 1.2.8 Stable -->

      <span class="d-none d-sm-inline-block"></span>
    </span>

    <span class="float-md-right d-none d-md-block">Made with
      💙
      By <a href="https://www.prokoders.com">ProKoders</a>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
export default {
  components: {
    BLink,
  },
}
</script>

<style scoped>
h4{
  color: black !important;
}
</style>
